/*********************************/

/*         INDEX                 */

/*================================
 *     01.  Variables            *
 *     02.  Bootstrap Custom     *
 *     03.  Components           *
 *     04.  General              *
 *     05.  Helper               *
 *     06.  Menu                 *
 *     07.  Home / Landing       *
 *     08.  Features / Services  *
 *     09.  Testimonial          *
 *     10.  Price                *
 *     11.  C.T.A                *
 *     12.  Blog / Careers / Job *
 *     13.  Work / Projects      *
 *     14.  Team                 *
 *     15.  User Pages           *
 *     16.  Countdown            *
 *     17.  Contact              *
 *     18.  Footer               *
 ================================*/

@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap");
.bg-primary {
  background-color: #6e62b5 !important;
}

.bg-soft-primary {
  background-color: #e2dff0 !important;
  border: 1px solid #e2dff0 !important;
  color: #6e62b5 !important;
}

.text-primary {
  color: #6e62b5 !important;
}

a {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #46276d !important;
}

.bg-secondary {
  background-color: #5a6d90 !important;
}

.bg-soft-secondary {
  background-color: rgba(90, 109, 144, 0.2) !important;
  border: 1px solid rgba(90, 109, 144, 0.2) !important;
  color: #5a6d90 !important;
}

.text-secondary {
  color: #5a6d90 !important;
}

a {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #506180 !important;
}

.bg-success {
  background-color: #2eca8b !important;
}

.bg-soft-success {
  background-color: rgba(46, 202, 139, 0.2) !important;
  border: 1px solid rgba(46, 202, 139, 0.2) !important;
  color: #2eca8b !important;
}

.text-success {
  color: #2eca8b !important;
}

a {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

a.text-success:hover,
a.text-success:focus {
  color: #29b57d !important;
}

.bg-warning {
  background-color: #f17425 !important;
}

.bg-soft-warning {
  background-color: rgba(241, 116, 37, 0.2) !important;
  border: 1px solid rgba(241, 116, 37, 0.2) !important;
  color: #f17425 !important;
}

.text-warning {
  color: #f17425 !important;
}

a {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ed650f !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.bg-soft-info {
  background-color: rgba(23, 162, 184, 0.2) !important;
  border: 1px solid rgba(23, 162, 184, 0.2) !important;
  color: #17a2b8 !important;
}

.text-info {
  color: #17a2b8 !important;
}

.text-white-60 {
  color: rgba(255, 255, 255, 0.6) !important;
}

a {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

a.text-info:hover,
a.text-info:focus {
  color: #148ea1 !important;
}

.bg-danger {
  background-color: #e43f52 !important;
}

.bg-soft-danger {
  background-color: rgba(228, 63, 82, 0.2) !important;
  border: 1px solid rgba(228, 63, 82, 0.2) !important;
  color: #e43f52 !important;
}

.text-danger {
  color: #e43f52 !important;
}

a {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #e1293e !important;
}

.bg-dark {
  background-color: #46276d !important;
}

.bg-soft-dark {
  background-color: rgba(60, 72, 88, 0.2) !important;
  border: 1px solid rgba(60, 72, 88, 0.2) !important;
  color: #46276d !important;
}

.text-dark {
  color: #6e62b5 !important;
}

a {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #46276d !important;
}

.bg-muted {
  background-color: #8492a6 !important;
}

.bg-soft-muted {
  background-color: rgba(132, 146, 166, 0.2) !important;
  border: 1px solid rgba(132, 146, 166, 0.2) !important;
  color: #8492a6 !important;
}

.text-muted {
  color: #8492a6 !important;
}

a {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

a.text-muted:hover,
a.text-muted:focus {
  color: #75859b !important;
}

.bg-light {
  background-color: #f8f9fc !important;
}

.bg-soft-light {
  background-color: rgba(248, 249, 252, 0.2) !important;
  border: 1px solid rgba(248, 249, 252, 0.2) !important;
  color: #f8f9fc !important;
}

.text-light {
  color: #f8f9fc !important;
}

a {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

a.text-light:hover,
a.text-light:focus {
  color: #e6eaf4 !important;
}

.bg-blue {
  background-color: #6e62b5 !important;
}

.bg-soft-blue {
  background-color: #e2dff0 !important;
  border: 1px solid #e2dff0 !important;
  color: #6e62b5 !important;
}

.text-blue {
  color: #6e62b5 !important;
}

a {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

a.text-blue:hover,
a.text-blue:focus {
  color: #46276d !important;
}

.bg-footer {
  background-color: #202942 !important;
}

.bg-soft-footer {
  background-color: rgba(32, 41, 66, 0.2) !important;
  border: 1px solid rgba(32, 41, 66, 0.2) !important;
  color: #202942 !important;
}

.text-footer {
  color: #202942 !important;
}

a {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

a.text-footer:hover,
a.text-footer:focus {
  color: #181e30 !important;
}

.bg-black {
  background: #161c2d !important;
}

.list-inline-item:not(:last-child) {
  margin-right: 0px;
  margin-bottom: 5px;
}

.rounded {
  border-radius: 6px !important;
}

.rounded-top {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.rounded-left {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.rounded-bottom {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.rounded-right {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.rounded-md {
  border-radius: 10px !important;
}

.rounded-lg {
  border-radius: 30px !important;
}

.border {
  border: 1px solid #e9ecef !important;
}

.border-top {
  border-top: 1px solid #e9ecef !important;
}

.border-bottom {
  border-bottom: 1px solid #e9ecef !important;
}

.border-left {
  border-left: 1px solid #e9ecef !important;
}

.border-right {
  border-right: 1px solid #e9ecef !important;
}

.small,
small {
  font-size: 90%;
}

.card .card-body {
  padding: 1.5rem;
}

.component-wrapper .title {
  font-size: 20px;
}

.component-wrapper.sticky-bar li {
  margin-bottom: 6px;
}

.component-wrapper.sticky-bar li:last-child {
  margin-bottom: 0px !important;
}

.component-wrapper.sticky-bar li a {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.component-wrapper.sticky-bar li a:hover,
.component-wrapper.sticky-bar li a:focus {
  color: #6e62b5 !important;
}

.btn-primary {
  background-color: #6e62b5 !important;
  border: 1px solid #6e62b5 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus {
  background-color: #4d447e !important;
  border-color: #4d447e !important;
  color: #ffffff !important;
}

.btn-soft-primary {
  background-color: #e2dff0 !important;
  border: 1px solid #e2dff0 !important;
  color: #6e62b5 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-soft-primary:hover,
.btn-soft-primary:focus,
.btn-soft-primary:active,
.btn-soft-primary.active,
.btn-soft-primary.focus {
  background-color: #6e62b5 !important;
  border-color: #6e62b5 !important;
  color: #ffffff !important;
}

.btn-outline-primary {
  border: 1px solid #6e62b5;
  color: #6e62b5;
  background-color: transparent;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: #6e62b5;
  border-color: #6e62b5;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-secondary {
  background-color: #5a6d90 !important;
  border: 1px solid #5a6d90 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active,
.btn-secondary.focus {
  background-color: #465571 !important;
  border-color: #465571 !important;
  color: #ffffff !important;
}

.btn-soft-secondary {
  background-color: rgba(90, 109, 144, 0.2) !important;
  border: 1px solid rgba(90, 109, 144, 0.2) !important;
  color: #5a6d90 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
}

.btn-soft-secondary:hover,
.btn-soft-secondary:focus,
.btn-soft-secondary:active,
.btn-soft-secondary.active,
.btn-soft-secondary.focus {
  background-color: #5a6d90 !important;
  border-color: #5a6d90 !important;
  color: #ffffff !important;
}

.btn-outline-secondary {
  border: 1px solid #5a6d90;
  color: #5a6d90;
  background-color: transparent;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: #5a6d90;
  border-color: #5a6d90;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
}

.btn-success {
  background-color: #2eca8b !important;
  border: 1px solid #2eca8b !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
  box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.focus {
  background-color: #25a06e !important;
  border-color: #25a06e !important;
  color: #ffffff !important;
}

.btn-soft-success {
  background-color: rgba(46, 202, 139, 0.2) !important;
  border: 1px solid rgba(46, 202, 139, 0.2) !important;
  color: #2eca8b !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
  box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
}

.btn-soft-success:hover,
.btn-soft-success:focus,
.btn-soft-success:active,
.btn-soft-success.active,
.btn-soft-success.focus {
  background-color: #2eca8b !important;
  border-color: #2eca8b !important;
  color: #ffffff !important;
}

.btn-outline-success {
  border: 1px solid #2eca8b;
  color: #2eca8b;
  background-color: transparent;
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.focus,
.btn-outline-success:not(:disabled):not(.disabled):active {
  background-color: #2eca8b;
  border-color: #2eca8b;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
  box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
}

.btn-warning {
  background-color: #f17425 !important;
  border: 1px solid #f17425 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
  box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.focus {
  background-color: #d55b0e !important;
  border-color: #d55b0e !important;
  color: #ffffff !important;
}

.btn-soft-warning {
  background-color: rgba(241, 116, 37, 0.2) !important;
  border: 1px solid rgba(241, 116, 37, 0.2) !important;
  color: #f17425 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
  box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
}

.btn-soft-warning:hover,
.btn-soft-warning:focus,
.btn-soft-warning:active,
.btn-soft-warning.active,
.btn-soft-warning.focus {
  background-color: #f17425 !important;
  border-color: #f17425 !important;
  color: #ffffff !important;
}

.btn-outline-warning {
  border: 1px solid #f17425;
  color: #f17425;
  background-color: transparent;
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.focus,
.btn-outline-warning:not(:disabled):not(.disabled):active {
  background-color: #f17425;
  border-color: #f17425;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
  box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
}

.btn-info {
  background-color: #17a2b8 !important;
  border: 1px solid #17a2b8 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.focus {
  background-color: #117a8b !important;
  border-color: #117a8b !important;
  color: #ffffff !important;
}

.btn-soft-info {
  background-color: rgba(23, 162, 184, 0.2) !important;
  border: 1px solid rgba(23, 162, 184, 0.2) !important;
  color: #17a2b8 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
}

.btn-soft-info:hover,
.btn-soft-info:focus,
.btn-soft-info:active,
.btn-soft-info.active,
.btn-soft-info.focus {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  color: #ffffff !important;
}

.btn-outline-info {
  border: 1px solid #17a2b8;
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.focus,
.btn-outline-info:not(:disabled):not(.disabled):active {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
}

.btn-danger {
  background-color: #e43f52 !important;
  border: 1px solid #e43f52 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
  box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger.focus {
  background-color: #d21e32 !important;
  border-color: #d21e32 !important;
  color: #ffffff !important;
}

.btn-soft-danger {
  background-color: rgba(228, 63, 82, 0.2) !important;
  border: 1px solid rgba(228, 63, 82, 0.2) !important;
  color: #e43f52 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
  box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
}

.btn-soft-danger:hover,
.btn-soft-danger:focus,
.btn-soft-danger:active,
.btn-soft-danger.active,
.btn-soft-danger.focus {
  background-color: #e43f52 !important;
  border-color: #e43f52 !important;
  color: #ffffff !important;
}

.btn-outline-danger {
  border: 1px solid #e43f52;
  color: #e43f52;
  background-color: transparent;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.focus,
.btn-outline-danger:not(:disabled):not(.disabled):active {
  background-color: #e43f52;
  border-color: #e43f52;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
  box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
}

.btn-dark {
  background-color: #46276d !important;
  border: 1px solid #46276d !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.btn-dark.focus {
  background-color: #272f3a !important;
  border-color: #272f3a !important;
  color: #ffffff !important;
}

.btn-soft-dark {
  background-color: rgba(60, 72, 88, 0.2) !important;
  border: 1px solid rgba(60, 72, 88, 0.2) !important;
  color: #46276d !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
}

.btn-soft-dark:hover,
.btn-soft-dark:focus,
.btn-soft-dark:active,
.btn-soft-dark.active,
.btn-soft-dark.focus {
  background-color: #46276d !important;
  border-color: #46276d !important;
  color: #ffffff !important;
}

.btn-outline-dark {
  border: 1px solid #46276d;
  color: #46276d;
  background-color: transparent;
}

.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.focus,
.btn-outline-dark:not(:disabled):not(.disabled):active {
  background-color: #46276d;
  border-color: #46276d;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
}

.btn-muted {
  background-color: #8492a6 !important;
  border: 1px solid #8492a6 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
}

.btn-muted:hover,
.btn-muted:focus,
.btn-muted:active,
.btn-muted.active,
.btn-muted.focus {
  background-color: #68788f !important;
  border-color: #68788f !important;
  color: #ffffff !important;
}

.btn-soft-muted {
  background-color: rgba(132, 146, 166, 0.2) !important;
  border: 1px solid rgba(132, 146, 166, 0.2) !important;
  color: #8492a6 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
}

.btn-soft-muted:hover,
.btn-soft-muted:focus,
.btn-soft-muted:active,
.btn-soft-muted.active,
.btn-soft-muted.focus {
  background-color: #8492a6 !important;
  border-color: #8492a6 !important;
  color: #ffffff !important;
}

.btn-outline-muted {
  border: 1px solid #8492a6;
  color: #8492a6;
  background-color: transparent;
}

.btn-outline-muted:hover,
.btn-outline-muted:focus,
.btn-outline-muted:active,
.btn-outline-muted.active,
.btn-outline-muted.focus,
.btn-outline-muted:not(:disabled):not(.disabled):active {
  background-color: #8492a6;
  border-color: #8492a6;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
}

.btn-light {
  background-color: #f8f9fc !important;
  border: 1px solid #f8f9fc !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
  box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light.active,
.btn-light.focus {
  background-color: #d4daed !important;
  border-color: #d4daed !important;
  color: #ffffff !important;
}

.btn-soft-light {
  background-color: rgba(248, 249, 252, 0.2) !important;
  border: 1px solid rgba(248, 249, 252, 0.2) !important;
  color: #f8f9fc !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
  box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
}

.btn-soft-light:hover,
.btn-soft-light:focus,
.btn-soft-light:active,
.btn-soft-light.active,
.btn-soft-light.focus {
  background-color: #f8f9fc !important;
  border-color: #f8f9fc !important;
  color: #ffffff !important;
}

.btn-outline-light {
  border: 1px solid #f8f9fc;
  color: #f8f9fc;
  background-color: transparent;
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.focus,
.btn-outline-light:not(:disabled):not(.disabled):active {
  background-color: #f8f9fc;
  border-color: #f8f9fc;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
  box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
}

.btn-blue {
  background-color: #6e62b5 !important;
  border: 1px solid #6e62b5 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active,
.btn-blue.active,
.btn-blue.focus {
  background-color: #4d447e !important;
  border-color: #4d447e !important;
  color: #ffffff !important;
}

.btn-soft-blue {
  background-color: #e2dff0 !important;
  border: 1px solid #e2dff0 !important;
  color: #6e62b5 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-soft-blue:hover,
.btn-soft-blue:focus,
.btn-soft-blue:active,
.btn-soft-blue.active,
.btn-soft-blue.focus {
  background-color: #6e62b5 !important;
  border-color: #6e62b5 !important;
  color: #ffffff !important;
}

.btn-outline-blue {
  border: 1px solid #6e62b5;
  color: #6e62b5;
  background-color: transparent;
}

.btn-outline-blue:hover,
.btn-outline-blue:focus,
.btn-outline-blue:active,
.btn-outline-blue.active,
.btn-outline-blue.focus,
.btn-outline-blue:not(:disabled):not(.disabled):active {
  background-color: #6e62b5;
  border-color: #6e62b5;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-footer {
  background-color: #202942 !important;
  border: 1px solid #202942 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
}

.btn-footer:hover,
.btn-footer:focus,
.btn-footer:active,
.btn-footer.active,
.btn-footer.focus {
  background-color: #0f131f !important;
  border-color: #0f131f !important;
  color: #ffffff !important;
}

.btn-soft-footer {
  background-color: rgba(32, 41, 66, 0.2) !important;
  border: 1px solid rgba(32, 41, 66, 0.2) !important;
  color: #202942 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
}

.btn-soft-footer:hover,
.btn-soft-footer:focus,
.btn-soft-footer:active,
.btn-soft-footer.active,
.btn-soft-footer.focus {
  background-color: #202942 !important;
  border-color: #202942 !important;
  color: #ffffff !important;
}

.btn-outline-footer {
  border: 1px solid #202942;
  color: #202942;
  background-color: transparent;
}

.btn-outline-footer:hover,
.btn-outline-footer:focus,
.btn-outline-footer:active,
.btn-outline-footer.active,
.btn-outline-footer.focus,
.btn-outline-footer:not(:disabled):not(.disabled):active {
  background-color: #202942;
  border-color: #202942;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
}

.btn {
  padding: 8px 20px;
  outline: none;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 0.5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 600;
  border-radius: 6px;
}

.btn:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn.btn-sm {
  padding: 7px 16px;
  font-size: 10px;
}

.btn.btn-lg {
  padding: 14px 30px;
  font-size: 16px;
}

.btn.searchbtn {
  padding: 6px 20px;
}

.btn.btn-pills {
  border-radius: 30px;
}

.btn.btn-light {
  color: #46276d !important;
  border: 1px solid #dee2e6 !important;
}

.btn.btn-light:hover,
.btn.btn-light:focus,
.btn.btn-light:active,
.btn.btn-light.active,
.btn.btn-light.focus {
  background-color: #d4daed !important;
  color: #46276d !important;
}

.btn.btn-soft-light {
  color: rgba(60, 72, 88, 0.5) !important;
  border: 1px solid #dee2e6 !important;
}

.btn.btn-soft-light:hover,
.btn.btn-soft-light:focus,
.btn.btn-soft-light:active,
.btn.btn-soft-light.active,
.btn.btn-soft-light.focus {
  color: #46276d !important;
}

.btn.btn-outline-light {
  border: 1px solid #dee2e6 !important;
  color: #46276d !important;
  background-color: transparent;
}

.btn.btn-outline-light:hover,
.btn.btn-outline-light:focus,
.btn.btn-outline-light:active,
.btn.btn-outline-light.active,
.btn.btn-outline-light.focus {
  background-color: #f8f9fc !important;
}

.btn.btn-icon {
  height: 36px;
  width: 36px;
  line-height: 34px;
  padding: 0;
}

.btn.btn-icon .icons {
  height: 16px;
  width: 16px;
  font-size: 16px;
}

.btn.btn-icon.btn-lg {
  height: 48px;
  width: 48px;
  line-height: 46px;
}

.btn.btn-icon.btn-lg .icons {
  height: 20px;
  width: 20px;
  font-size: 20px;
}

.btn.btn-icon.btn-sm {
  height: 30px;
  width: 30px;
  line-height: 28px;
}

button:not(:disabled) {
  outline: none;
}

.shadow {
  -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
}

.shadow-lg {
  -webkit-box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
  box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
}

.shadow-md {
  -webkit-box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
  box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
}

.avatar.avatar-ex-sm {
  max-height: 25px;
}

.avatar.avatar-sm {
  max-height: 40px;
}

.avatar.avatar-md-sm {
  height: 45px;
  width: 45px;
}

.avatar.avatar-small {
  height: 75px;
  width: 100px;
}

.avatar.avatar-md-md {
  height: 80px;
  width: 80px;
}

.avatar.avatar-medium {
  height: 110px;
  width: 150px;
}

.avatar.avatar-large {
  height: 140px;
  width: 140px;
}

.avatar.avatar-ex-large {
  height: 180px;
  width: 180px;
}

@-webkit-keyframes animate-positive {
  0% {
    width: 0;
  }
}

@keyframes animate-positive {
  0% {
    width: 0;
  }
}

.blockquote {
  border-left: 3px solid #dee2e6;
  border-radius: 6px;
  font-size: 16px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-size: 14px;
  font-weight: 700;
}

.form-group .form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  color: #46276d;
  height: 42px;
  font-size: 13px;
  border-radius: 6px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.form-group .form-control:focus,
.form-group .form-control.active {
  border-color: #6e62b5;
}

.form-group textarea {
  height: 120px !important;
}

.form-group textarea.form-control {
  line-height: 25px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
  opacity: 1;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #ffffff;
  border-color: #6e62b5;
  background-color: #6e62b5;
}

.custom-control-input:focus ~ .custom-control-label::before,
.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #6e62b5;
}

.custom-control-label {
  cursor: pointer;
}

.custom-control-label:before,
.custom-control-label:after {
  top: 2px;
}

.custom-switch .custom-control-label:after {
  top: 4px;
}

.subcribe-form input {
  padding: 14px 20px;
  width: 100%;
  color: #46276d !important;
  border: none;
  outline: none !important;
  padding-right: 160px;
  padding-left: 30px;
  background-color: rgba(255, 255, 255, 0.8);
}

.subcribe-form button {
  position: absolute;
  top: 6px;
  right: 6px;
  outline: none !important;
}

.subcribe-form form {
  position: relative;
  max-width: 600px;
  margin: 0px auto;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive .table td,
.table-responsive .table th {
  vertical-align: middle;
}

.table-responsive .table-center th {
  vertical-align: middle !important;
}

.table-responsive .table-center tbody tr:hover {
  color: #46276d;
  background-color: #f8f9fa;
}

.table-responsive .table-center.invoice-tb th,
.table-responsive .table-center.invoice-tb td {
  text-align: end;
}

.modal-open {
  padding-right: 0 !important;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(45deg, #ffffff, #ffffff);
  z-index: 9999999;
}

#preloader #status {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#preloader #status .spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

#preloader #status .spinner .double-bounce1,
#preloader #status .spinner .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #6e62b5;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

#preloader #status .spinner .double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.social-icon li a {
  color: #46276d;
  border: 1px solid #46276d;
  display: inline-block;
  height: 32px;
  width: 32px;
  line-height: 28px;
  text-align: center;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  overflow: hidden;
  position: relative;
}

.social-icon li a .fea-social {
  stroke-width: 2;
}

.social-icon li a:hover {
  background-color: #6e62b5;
  border-color: #6e62b5 !important;
  color: #ffffff !important;
}

.social-icon li a:hover .fea-social {
  fill: #6e62b5;
}

.social-icon.social li a {
  color: #adb5bd;
  border-color: #adb5bd;
}

.back-to-top {
  z-index: 99;
  position: fixed;
  bottom: 30px;
  left: 30px;
  display: none;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.back-to-top .icons {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.back-to-top:hover {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.back-to-top:hover .icons {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.back-to-home {
  position: absolute;
  top: 4%;
  right: 2%;
  z-index: 1;
}

.sticky-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}

.fea {
  stroke-width: 1.8;
}

.fea.icon-sm {
  height: 20px;
  width: 25px;
}

.fea.icon-ex-md {
  height: 20px;
  width: 20px;
}

.fea.icon-m-md {
  height: 28px;
  width: 28px;
}

.fea.icon-md {
  height: 35px;
  width: 35px;
}

.fea.icon-lg {
  height: 42px;
  width: 42px;
}

.fea.icon-ex-lg {
  height: 52px;
  width: 52px;
}

.display-1 {
  font-size: 80px !important;
}

.display-2 {
  font-size: 72px !important;
}

.display-3 {
  font-size: 64px !important;
}

.display-4 {
  font-size: 56px !important;
}

[class^="uil-"]:before,
[class*=" uil-"]:before {
  margin: 0;
}

.uim-svg {
  vertical-align: 0 !important;
}

@media (max-width: 768px) {
  .dime-home {
    padding-top: 80px;
  }

  .mt-100 {
    margin-top: 0px !important;
  }

  .img-superfluid {
    max-width: 100% !important;
  }

  .page-next-level .page-next {
    top: 70px;
  }
  .display-1 {
    font-size: 56px !important;
  }
  .display-2 {
    font-size: 50px !important;
  }
  .display-3 {
    font-size: 42px !important;
  }
  .display-4 {
    font-size: 36px !important;
  }
  .img-illustration {
    visibility: hidden;
    height: 0 !important;
  }
}

body {
  font-family: "Nunito", sans-serif;
  overflow-x: hidden !important;
  font-size: 16px;
  color: #161c2d;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Nunito", sans-serif;
  line-height: 1.5;
  font-weight: 600;
}

h5 {
  font-size: 1rem;
}

::-moz-selection {
  background: rgba(47, 85, 212, 0.9);
  color: #ffffff;
}

::selection {
  background: rgba(47, 85, 212, 0.9);
  color: #ffffff;
}

a {
  text-decoration: none !important;
}

p {
  line-height: 1.6;
}

.section {
  padding: 50px 0;
  position: relative;
}

.section-two {
  padding: 60px 0;
  position: relative;
}

.bg-overlay {
  background-color: rgba(60, 72, 88, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.bg-overlay-gradient {
  background-color: rgba(47, 85, 212, 0.6);
}

.bg-overlay-white {
  background-color: rgba(255, 255, 255, 0.5);
}

.bg-overlay-gradient2 {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2eca8b),
    color-stop(#6e62b5),
    to(#2eca8b)
  );
  background-image: linear-gradient(90deg, #2eca8b, #6e62b5, #2eca8b);
  opacity: 0.5;
}

.title-heading {
  line-height: 26px;
}

.title-heading .heading {
  font-size: 45px;
  letter-spacing: 1px;
}

.title-heading .para-desc {
  font-size: 18px;
}

.section-title {
  position: relative;
}

.section-title .title {
  letter-spacing: 0.5px;
  font-size: 30px;
}

.text-shadow-title {
  text-shadow: 2px 0 0 #ffffff, -2px 0 0 #ffffff,
    0 4px 0 rgba(255, 255, 255, 0.4), 0 -2px 0 #ffffff, 1px 1px #ffffff,
    -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff;
}

.para-desc {
  max-width: 600px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-100 {
  margin-top: 100px;
}

.shape {
  position: absolute;
  pointer-events: none;
  right: 0;
  bottom: -2px;
  left: 0;
}

.shape > svg {
  -webkit-transform: scale(2);
  transform: scale(2);
  width: 100%;
  height: auto;
  -webkit-transform-origin: top center;
  transform-origin: top center;
}

.mover {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
}

@keyframes mover {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
}

.feature-posts-placeholder {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 66.6%;
}

.spin-anything {
  -webkit-animation: spinner-animation 5s linear infinite;
  animation: spinner-animation 5s linear infinite;
}

@-webkit-keyframes spinner-animation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-animation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .margin-top-100 {
    margin-top: 100px;
  }
  .margin-top-100 .heading {
    font-size: 40px;
  }
  .margin-top-70 {
    margin-top: 70px;
  }
  .margin-top-70 .heading {
    font-size: 40px;
  }
  .margin-top-30 {
    margin-top: 30px;
  }
  .margin-top-30 .heading {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .section {
    padding: 30px 0;
  }
  .title-heading .heading {
    font-size: 35px !important;
  }
  .title-heading .para-desc {
    font-size: 18px;
  }
  .section-title .title {
    font-size: 24px;
  }
  .mt-60 {
    margin-top: 20px;
  }

  .pb-2,
  .py-2 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 425px) {
  .shape {
    bottom: -4px;
  }
}

@media (max-width: 425px) {
  .feature-posts-placeholder {
    height: 80%;
  }
}

#topnav {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1030;
  background-color: transparent;
  border: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#topnav .logo {
  float: left;
  color: #46276d !important;
}

#topnav .logo .l-dark {
  display: none;
}

#topnav .logo .l-light {
  display: inline-block;
}

#topnav .has-submenu.active a {
  color: #ffffff;
}

#topnav .has-submenu.active .submenu li.active > a {
  color: #6e62b5 !important;
}

#topnav .has-submenu.active.active .menu-arrow {
  border-color: #6e62b5;
}

#topnav .has-submenu {
  position: relative;
}

#topnav .has-submenu .submenu .submenu-arrow {
  border: solid #46276d;
  border-radius: 0.5px;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  right: 20px;
  top: 14px;
}

#topnav .has-submenu .submenu .has-submenu:hover .submenu-arrow {
  border-color: #6e62b5;
}

#topnav .navbar-toggle {
  border: 0;
  position: relative;
  width: 60px;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

#topnav .navbar-toggle .lines {
  width: 25px;
  display: block;
  position: relative;
  margin: 28px 20px;
  height: 18px;
}

#topnav .navbar-toggle span {
  height: 2px;
  width: 100%;
  background-color: #46276d;
  display: block;
  margin-bottom: 5px;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

#topnav .navbar-toggle span:last-child {
  margin-bottom: 0;
}

#topnav .demo-button {
  float: right;
  line-height: 68px;
}

#topnav .demo-button .demo-btn-primary {
  display: none;
}

#topnav .demo-button .demo-btn-light {
  display: inline-block;
}

#topnav .navbar-toggle.open span {
  position: absolute;
}

#topnav .navbar-toggle.open span:first-child {
  top: 6px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

#topnav .navbar-toggle.open span:nth-child(2) {
  visibility: hidden;
}

#topnav .navbar-toggle.open span:last-child {
  width: 100%;
  top: 6px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#topnav .navbar-toggle.open span:hover {
  background-color: #6e62b5;
}

#topnav .navbar-toggle:hover,
#topnav .navbar-toggle:focus,
#topnav .navbar-toggle .navigation-menu > li > a:hover,
#topnav .navbar-toggle:focus {
  background-color: transparent;
}

#topnav .navigation-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

#topnav .navigation-menu > li:hover > a,
#topnav .navigation-menu > li.active > a {
  color: #6e62b5 !important;
}

#topnav .navigation-menu > li > a {
  display: block;
  color: #46276d;
  font-size: 13px;
  background-color: transparent !important;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 24px;
  text-transform: uppercase;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  font-family: "Nunito", sans-serif;
  padding-left: 15px;
  padding-right: 15px;
}

#topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li > a:active {
  color: #6e62b5;
}

#topnav .navigation-menu .has-submenu .menu-arrow {
  border: solid #46276d;
  border-radius: 0.5px;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  right: -1px;
  top: 30px;
}

#topnav .navigation-menu .has-submenu:hover .menu-arrow {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

#topnav .menu-extras {
  float: right;
}

#topnav.scroll {
  background-color: #ffffff;
  border: none;
  -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

#topnav.scroll .navigation-menu > li > a {
  color: #46276d;
}

#topnav.scroll .navigation-menu > li > .menu-arrow {
  border-color: #46276d;
}

#topnav.scroll .navigation-menu > li:hover > a,
#topnav.scroll .navigation-menu > li.active > a {
  color: #6e62b5;
}

#topnav.scroll .navigation-menu > li:hover > .menu-arrow,
#topnav.scroll .navigation-menu > li.active > .menu-arrow {
  border-color: #6e62b5;
}

#topnav.defaultscroll.dark-menubar .logo {
  line-height: 70px;
}

#topnav.defaultscroll.scroll .logo {
  line-height: 62px;
}

#topnav.defaultscroll.scroll.dark-menubar .logo {
  line-height: 62px;
}

#topnav.nav-sticky {
  background: #ffffff;
  -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

#topnav.nav-sticky .navigation-menu.nav-light > li > a {
  color: #46276d;
}

#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  color: #6e62b5 !important;
}

#topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow,
#topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow {
  border-color: #6e62b5 !important;
}

#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a,
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  color: #6e62b5 !important;
}

#topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
  border-color: #46276d;
}

#topnav.nav-sticky .demo-button .demo-btn-primary {
  display: inline-block;
}

#topnav.nav-sticky .demo-button .demo-btn-light {
  display: none;
}

#topnav.nav-sticky .logo .l-dark {
  display: inline-block;
}

#topnav.nav-sticky .logo .l-light {
  display: none;
}

.logo {
  font-weight: 700;
  font-size: 24px;
  padding: 0 0 6px;
  letter-spacing: 1px;
  line-height: 68px;
}

@media (min-width: 992px) {
  #topnav .navigation-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #topnav
    .navigation-menu
    > .has-submenu
    > .submenu
    > .has-submenu
    > .submenu::before {
    top: 20px;
    margin-left: -35px;
    -webkit-box-shadow: 1px 1px 0px 0px rgba(60, 72, 88, 0.15);
    box-shadow: 1px 1px 0px 0px rgba(60, 72, 88, 0.15);
    border-color: transparent #ffffff #ffffff transparent;
  }
  #topnav .navigation-menu > .has-submenu:hover .menu-arrow {
    top: 33px !important;
  }
  #topnav .navigation-menu > .has-submenu.active .menu-arrow {
    top: 30px;
  }
  #topnav .navigation-menu > li .submenu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    padding: 15px 0;
    list-style: none;
    min-width: 180px;
    visibility: hidden;
    opacity: 0;
    margin-top: 10px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border-radius: 6px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  }
  #topnav .navigation-menu > li .submenu:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 45px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 7px solid #495057;
    border-color: transparent transparent #ffffff #ffffff;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-box-shadow: -2px 2px 2px -1px rgba(60, 72, 88, 0.15);
    box-shadow: -2px 2px 2px -1px rgba(60, 72, 88, 0.15);
  }
  #topnav .navigation-menu > li .submenu li {
    position: relative;
  }
  #topnav .navigation-menu > li .submenu li a {
    display: block;
    padding: 10px 20px;
    clear: both;
    white-space: nowrap;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    font-weight: 700;
    color: #46276d !important;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  #topnav .navigation-menu > li .submenu li a:hover {
    color: #6e62b5 !important;
  }
  #topnav .navigation-menu > li .submenu li ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
  #topnav .navigation-menu > li .submenu.megamenu {
    white-space: nowrap;
    width: auto;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li {
    overflow: hidden;
    min-width: 180px;
    display: inline-block;
    vertical-align: top;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
    left: 100%;
    top: 0;
    margin-left: 10px;
    margin-top: -1px;
  }
  #topnav .navigation-menu > li .submenu > li .submenu {
    left: 106%;
    top: 0;
    margin-left: 10px;
    margin-top: -1px;
  }
  #topnav .navigation-menu > li > a {
    padding-top: 25px;
    padding-bottom: 25px;
    min-height: 62px;
  }
  #topnav .navigation-menu > li:hover > .menu-arrow {
    border-color: #6e62b5;
  }
  #topnav .navigation-menu > li:hover > a,
  #topnav .navigation-menu > li.active > a {
    color: #6e62b5 !important;
  }
  #topnav .navigation-menu > li.last-elements .submenu {
    left: auto;
    right: 0;
  }
  #topnav .navigation-menu > li.last-elements .submenu:before {
    left: auto;
    right: 10px;
  }
  #topnav
    .navigation-menu
    > li.last-elements
    .submenu
    > li.has-submenu
    .submenu {
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-right: 10px;
  }
  #topnav .navigation-menu.nav-light > li > a {
    color: rgba(255, 255, 255, 0.5);
  }
  #topnav .navigation-menu.nav-light > li.active > a {
    color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > .menu-arrow {
    border-color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > a {
    color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-light .has-submenu .menu-arrow {
    border-color: rgba(255, 255, 255, 0.5);
  }
  #topnav .navigation-menu.nav-light .has-submenu.active .menu-arrow {
    border-color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-right {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  #topnav .navigation-menu.nav-left {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  #topnav .navigation-menu.nav-left > li.last-elements .submenu {
    left: 0 !important;
    right: auto !important;
  }
  #topnav .navigation-menu.nav-left > li.last-elements .submenu:before {
    left: 45px !important;
    right: auto !important;
  }
  #topnav .demo-button {
    padding-left: 15px;
    margin-left: 15px;
  }
  #topnav .navbar-toggle {
    display: none;
  }
  #topnav #navigation {
    display: block !important;
  }
  #topnav.scroll {
    top: 0;
  }
  #topnav.scroll .navigation-menu > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #topnav.scroll-active .navigation-menu > li > a {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

@media (max-width: 991px) {
  #topnav {
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    min-height: 74px;
  }
  #topnav .logo .l-dark {
    display: inline-block !important;
  }
  #topnav .logo .l-light {
    display: none !important;
  }
  #topnav .container {
    width: auto;
  }
  #topnav #navigation {
    max-height: 400px;
  }
  #topnav .navigation-menu {
    float: none;
  }
  #topnav .navigation-menu > li {
    float: none;
  }
  #topnav .navigation-menu > li .submenu {
    display: none;
    list-style: none;
    padding-left: 20px;
    margin: 0;
  }
  #topnav .navigation-menu > li .submenu li a {
    display: block;
    position: relative;
    padding: 7px 15px;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.04em;
    font-weight: 700;
    color: #46276d !important;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  #topnav .navigation-menu > li .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li .submenu .submenu {
    display: none;
    list-style: none;
  }
  #topnav .navigation-menu > li .submenu .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul {
    list-style: none;
    padding-left: 0;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
    display: block;
    position: relative;
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    color: #8492a6;
  }
  #topnav .navigation-menu > li > a {
    color: #46276d;
    padding: 10px 20px;
  }
  #topnav .navigation-menu > li > a:after {
    position: absolute;
    right: 15px;
  }
  #topnav .navigation-menu > li > a:hover,
  #topnav .navigation-menu > li .submenu li a:hover,
  #topnav .navigation-menu > li.has-submenu.open > a {
    color: #6e62b5;
  }
  #topnav .menu-extras .menu-item {
    border-color: #8492a6;
  }
  #topnav .navbar-header {
    float: left;
  }
  #topnav .demo-button .demo-btn-primary {
    display: inline-block !important;
  }
  #topnav .demo-button .demo-btn-light {
    display: none;
  }
  #topnav .has-submenu .submenu .submenu-arrow {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    right: 20px;
    top: 12px;
  }
  #topnav .has-submenu.active a {
    color: #6e62b5;
  }
  #navigation {
    position: absolute;
    top: 74px;
    left: 0;
    width: 100%;
    display: none;
    height: auto;
    padding-bottom: 0;
    overflow: auto;
    border-top: 1px solid #f1f3f9;
    border-bottom: 1px solid #f1f3f9;
    background-color: #ffffff;
  }
  #navigation.open {
    display: block;
    overflow-y: auto;
  }
}

@media (max-width: 768px) {
  #topnav .navigation-menu .has-submenu .menu-arrow {
    right: 16px;
    top: 16px;
  }
  #topnav .demo-button {
    padding: 3px 0;
  }
}

@media (min-width: 768px) {
  #topnav .navigation-menu > li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
  }
  #topnav
    .navigation-menu
    > li.has-submenu:hover
    > .submenu
    > li.has-submenu:hover
    > .submenu {
    visibility: visible;
    opacity: 1;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-toggle {
    display: block;
  }
}

@media (max-width: 375px) {
  #topnav .demo-button {
    display: none;
  }

  #topnav .navbar-toggle .lines {
    margin-right: 0 !important;
  }
}

.bg-home {
  height: 100vh;
  background-size: cover;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-animation-left:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 70%;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  -webkit-clip-path: polygon(0 0, 90% 0, 70% 100%, 0% 100%);
  clip-path: polygon(0 0, 90% 0, 70% 100%, 0% 100%);
}

.bg-animation-left.dark-left:after {
  background: rgba(60, 72, 88, 0.6);
}

.bg-animation-left.crypto-home:after {
  background: #6e62b5;
  width: 80%;
  -webkit-clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
}

.bg-animation-left.dime-home:after {
  background: url("../images/bg.png") #6e62b5;
  width: 100%;
  -webkit-clip-path: circle(65% at 40% 25%);
  clip-path: circle(65% at 40% 25%);
  top: -30px;
}

.features .image:before {
  content: "";
  position: absolute;
  bottom: 5px;
  right: -15px;
  width: 70px;
  height: 70px;
  border-radius: 6px;
  -webkit-transform: rotate(33.75deg);
  transform: rotate(33.75deg);
  background: linear-gradient(45deg, transparent, rgba(47, 85, 212, 0.09));
  z-index: -1;
}

.features .ride-image {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.features .ride-image img {
  border-radius: 100% 100% 100% 0;
}

.features .title-2 {
  font-size: 20px;
}

.features:hover .image:before {
  background: rgba(47, 85, 212, 0.07);
  -webkit-animation: spinner-border 5s linear infinite;
  animation: spinner-border 5s linear infinite;
}

.features.fea-primary {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.features.fea-primary .content .title {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.features.fea-primary .content .title:hover {
  color: #6e62b5 !important;
}

.features.fea-primary .big-icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  opacity: 0.015;
  font-size: 180px;
}

.features.fea-primary:hover {
  -webkit-box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15);
  box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15);
  background-color: #6e62b5 !important;
  color: #ffffff !important;
}

.features.fea-primary:hover .icon2,
.features.fea-primary:hover .para {
  color: rgba(255, 255, 255, 0.5) !important;
}

.features.fea-primary:hover .content,
.features.fea-primary:hover .icon2 {
  z-index: 2;
}

.features.fea-primary:hover .big-icon {
  opacity: 0.05;
}

.features .title,
.key-feature .title {
  font-size: 18px;
}

.features .icon,
.key-feature .icon {
  height: 60px;
  width: 60px;
  line-height: 60px;
}

.key-feature {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.key-feature .icon {
  background: linear-gradient(45deg, transparent, rgba(47, 85, 212, 0.15));
}

.key-feature:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
  box-shadow: 0 5px 13px rgba(60, 72, 88, 0.2) !important;
}

.features-absolute {
  margin: -200px 0 0px;
}

.features-absolute.blog-search {
  margin: -120px 0 0px;
}

.catagories {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.catagories:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

.catagories a:hover {
  color: #6e62b5 !important;
}

.app-feature-shape-right:after,
.app-feature-shape-left:after {
  bottom: 0rem;
  width: 30rem;
  height: 40rem;
  border-radius: 10rem;
}

.app-feature-shape-right:after {
  left: 10rem;
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}

.app-feature-shape-left:after {
  right: 10rem;
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
}

.app-subscribe {
  position: absolute;
  top: -200px;
}

.saas-feature-shape-left:after,
.saas-feature-shape-right:after {
  bottom: -3rem;
  width: 35rem;
  height: 35rem;
  border-radius: 15rem;
  -webkit-transform: rotate(145deg);
  transform: rotate(145deg);
}

.saas-feature-shape-left:after {
  left: -10rem;
}

.saas-feature-shape-right:after {
  right: -10rem;
}

.app-feature-shape-right:after,
.app-feature-shape-left:after,
.saas-feature-shape-left:after,
.saas-feature-shape-right:after,
.social-feature-left:after,
.social-feature-right:after {
  background: #f0eff7;
  -webkit-box-shadow: 0 0 40px #f0eff7;
  box-shadow: 0 0 40px #f0eff7;
}

.app-feature-shape-right:after,
.app-feature-shape-left:after,
.saas-feature-shape-left:after,
.saas-feature-shape-right:after,
.social-feature-left:after,
.social-feature-right:after {
  content: "";
  position: absolute;
  z-index: -1;
}

.social-feature-left:after,
.social-feature-right:after {
  width: 450px;
  height: 520px;
  border-radius: 30px;
  bottom: -75px;
}

.social-feature-left:after {
  right: -200px;
}

.social-feature-right:after {
  left: -200px;
}

.explore-feature {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.explore-feature .icon {
  height: 80px;
  width: 80px;
  line-height: 80px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.explore-feature:hover {
  -webkit-box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
  box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
}

.explore-feature:hover .icon,
.explore-feature:hover .title {
  color: #6e62b5 !important;
}

@media (max-width: 768px) {
  .app-feature-shape-right:after {
    left: 0;
  }
  .app-feature-shape-left:after {
    right: 0;
  }
  .social-feature-left:after,
  .social-feature-right:after {
    width: 300px;
    height: 400px;
    bottom: -65px;
  }
  .features-absolute {
    margin: -140px 0 0;
  }
  .features-absolute.blog-search {
    margin: -90px 0 0px;
  }
}

@media screen and (max-width: 767px) {
  .process-arrow:after {
    display: none;
  }

  .app-subscribe {
    position: relative;
    top: 0;
  }
  .saas-feature-shape-left:after,
  .saas-feature-shape-right:after {
    bottom: -1rem;
    width: 25rem;
    height: 25rem;
    border-radius: 10rem;
  }
}

@media (max-width: 575px) {
  .social-feature-left:after,
  .social-feature-right:after {
    bottom: -35px;
  }
  .social-feature-left:after {
    right: 60px;
  }
  .social-feature-right:after {
    left: 60px;
  }
}

.owl-theme .owl-dots .owl-dot span {
  background: #6e62b5 !important;
}

.customer-testi {
  cursor: e-resize;
}

.owl-theme .owl-dots .owl-dot span {
  border-radius: 3px;
  background: rgba(47, 85, 212, 0.5) !important;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots.clickable .owl-dot:hover span {
  background: #6e62b5 !important;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.owl-carousel .owl-item img {
  display: block;
  width: auto;
}

.slick-slider {
  overflow-x: hidden !important;
  cursor: pointer;
}

.play-btn {
  height: 78px;
  width: 78px;
  font-size: 30px;
  line-height: 76px;
  border-radius: 50%;
  display: inline-block;
  color: #ffffff;
}

.play-icon {
  position: absolute;
  bottom: 50%;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  right: 0;
  left: 0;
  text-align: center;
}

.play-icon i {
  height: 75px;
  -webkit-text-stroke: 2px #6e62b5;
  -webkit-text-fill-color: transparent;
  width: 75px;
  font-size: 25px;
  line-height: 75px;
  display: inline-block;
}

.play-icon i:hover {
  -webkit-text-stroke: 2px #6e62b5;
  -webkit-text-fill-color: #6e62b5;
}

.mfp-iframe-holder .mfp-content {
  max-width: 1110px;
}

.testimonial-container.testimonial-regular img,
.testimonial-container.testimonial-regular .overlay-work,
.testimonial-container.testimonial-regular .content,
.testimonial-container.testimonial-regular .client,
.testimonial-container.testimonial-regular .read_more,
.testimonial-container.testimonial-regular .icons,
.testimonial-container.testimonial-regular .icons .work-icon {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.contact-detail .content .title {
  font-size: 20px;
}

.form-group .icons {
  position: absolute;
  top: 43px;
  left: 18px;
}

.footer {
  background: #202942;
  padding: 60px 0 10px 0;
  position: relative;
  color: #adb5bd;
}

.footer .logo-footer {
  font-size: 22px;
}

.footer .footer-head {
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 500;
}

.footer .foot-subscribe .form-control {
  color: #f8f9fc;
}

.footer .foot-subscribe .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.footer .foot-subscribe.foot-white .form-control {
  color: #adb5bd;
}

.footer .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

.footer .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

.footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

.footer .foot-subscribe input::placeholder {
  color: #adb5bd;
}

.footer .text-foot {
  color: #adb5bd;
}

.footer .footer-list {
  margin-bottom: 0;
}

.footer .footer-list li {
  margin-bottom: 10px;
}

.footer .footer-list li a {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.footer .footer-list li a:hover {
  color: #e6e8ea;
}

.footer .footer-list li:last-child {
  margin-bottom: 0;
}

.footer.footer-bar {
  padding: 30px 0;
  border-top: 1px solid #283353;
}

.customer-testi {
  justify-content: center;
}

.img-superfluid {
  max-width: 150%;
  height: auto;
}

.medium-zoom-image--opened {
  z-index: 999;
}

img.shaded {
  -webkit-box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
  box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
  -moz-box-shadow: 0 10px 25px rgba(60, 72, 88, 0.15) !important;
}
